.encabezado {
    display: flex;
    flex-direction: row;
    margin-bottom: 175px;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 26px 0px 26px;
    border: 1.3px solid #E6E6E6;
    border-radius: 5px 5px 0px 0px;
    padding: 11px;
}

.element-CheckIn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FF007E;
    margin: 0px 26px 0px 26px;
    /* border-radius: 0px 0px 5px 5px; */
    padding: 11px;
}

.boxEnd {
    border: 1.3px solid #E6E6E6;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 26px 15px 26px;
    padding: 3px;
}

.check {
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #A9A9BA;
}

.hora {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.53px;
}

/* h5 */
.duracionReserva {
    color: #A9A9BA;
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

/* h3 */
.reservar { 
    color: #F5F5F5;
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    cursor: pointer;
}