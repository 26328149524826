* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Calibri', 'Helvetica Neue', sans-serif; 
}


