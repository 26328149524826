.containerInfoCliente {
    display: flex;
    flex-direction: column;
    border: 1.3px solid #E6E6E6;
    border-radius: 5px;
    padding: 20px;
    width: 258px;
    align-items: flex-start;
    margin-top: 20px;
}

.nombres {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
}

.container2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.icons {
    display: flex;
    flex-direction: row;
    margin: 5px 12px 5px 10px;
}